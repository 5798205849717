import config from "../config"

const needUpdate = async () => {
  try {
    const newVersion = await (await fetch(new URL(window.location).origin)).text()
    if (!newVersion) return false

    const currentVersion =
      [...document.getElementsByTagName('meta')]
        .filter((el) => el.name === 'rcl-app-version')[0].content

    const metaStr = `meta name="rcl-app-version" content="${currentVersion}"`

    return newVersion.indexOf(metaStr) < 0
  } catch (e) {
    // Something bad happened, probably unable to connect to server. Do not reload page.
    return false
  }
}

const reloadIfNeedUpdate = () => {
  needUpdate().then((reload) => {
    if (reload) window.location.reload()
  })
}

const setUpdateListener = () => {
  window.onfocus = reloadIfNeedUpdate //Every time user comes back
  window.onload = reloadIfNeedUpdate //Every time browser loads the page
  setInterval(reloadIfNeedUpdate, config.UPDATE_CHECK_INTERVAL) //Every UPDATE_CHECK_INTERVAL
}

export default setUpdateListener