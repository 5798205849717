import React from "react"
import { Editor } from '@tinymce/tinymce-react'

import ModalFilePicker from "./ModalFilePicker"
import PdfViewer from "./PdfViewer"
import Spinner from "./Spinner"

import lang from "../lang/es"
import { toast } from "react-toastify"
import { getCustomTrack, setCustomTrack } from "../actions/customContent"
import config from '../config'
import promisedFileReader from "../utils/PromisedFileReader"
import pdfDownload from "../actions/pdfDownload"

import './CustomContentEditor.css'

class CustomContentEditor extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showing: false,
      editor: null,
      title: '',
      initialTitle: '',
      initialContent: '',
      ready: false,
      contentMode: null, // text, pdf, null
      showFilePicker: false,
      pdfData: null,
      pdfObjectUrl: null
    }
  }

  componentDidMount = () => {
    setTimeout(
      () => this.setState({ showing: true }),
      100)

    if (this.props.editCustomContent.contentId)
      getCustomTrack(
        this.props.editCustomContent.contentId,
        true,
        this.contentLoaded
      )
    else this.contentLoaded()
  }

  componentWillUnmount = () => {
    if (this.state.pdfObjectUrl) URL.revokeObjectURL(this.state.pdfObjectUrl)
    if (this.state.initialContent) URL.revokeObjectURL(this.state.initialContent)
    // There is no need to check if initialContent is actually an ObjectURL
    // as revoke will not throw any errors if no passed object is not an ObjectURL.
    // Also, there is no way to check if an URL is an ObjectURL or not.
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  contentLoaded = async (content) => {
    let contentMode = 'text' // defaults to text mode
    if (content?.text?.pdf) contentMode = 'pdf'

    let initialContent = lang.CUSTOM_CONTENT_EDITOR_PLACEHOLDER
    if (contentMode === 'pdf') initialContent = URL.createObjectURL(await pdfDownload(content.text.pdf))
    else if (content?.text?.html) initialContent = content.text.html

    this.setState({
      title: content?.text?.title || '',
      initialTitle: content?.text?.title || '',
      initialContent,
      ready: true,
      contentMode
    })
  }

  // TinyMCE function to remove images (why? should we allow images? we already allow PDF files...)
  /*pastePreprocess = (plugin, args) => {
    args.content = args.content.replace(/<img [^>]*>/g, '')
    return args
  }*/

  save = () => {
    if (!this.state.title) {
      toast.error(lang.CUSTOM_CONTENT_EDITOR_TITLE_MISSING, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      const title = this.state.title

      if (this.state.contentMode === 'pdf') {
        if (title !== this.state.initialTitle || this.state.pdfData) {
          setCustomTrack(
            this.props.editCustomContent.contentId,
            { title, pdfData: this.state.pdfData }, // pdfData will be null if PDF file was not changed
            this.saved
          )
        } else this.close()
      } else {
        // Content from TinyMCE editor
        const html = this.state.editor.getContent({ format: "html" })

        if (title !== this.state.initialTitle || html !== this.state.initialContent) {
          setCustomTrack(
            this.props.editCustomContent.contentId,
            { title, html },
            this.saved
          )
        } else this.close()
      }
    }
  }

  saved = (id) => {
    if (id) {
      toast.info(lang.CUSTOM_CONTENT_EDITOR_SAVE_SUCCESS, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    } else {
      toast.error(lang.CUSTOM_CONTENT_EDITOR_SAVE_ERROR, {
        position: "top-center",
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      })
    }
    this.props.editCustomContent.cb(id)
    this.close()
  }

  close = () => {
    this.setState({ showing: false })
    setTimeout(() => {
      if (!this.state.showing) this.props.close()
    }, 550) // Close animation duration
  }

  toggleFilePicker = () =>
    this.setState({ showFilePicker: !this.state.showFilePicker })

  uploadPdfFile = async (pdfFile) => { // TODO flag a PDF change or check the change before uploading to AUTH
    this.setState({ ready: false })
    if (this.state.pdfObjectUrl) URL.revokeObjectURL(this.state.pdfObjectUrl) // Release previous if any
    const pdfObjectUrl = URL.createObjectURL(pdfFile)
    const fileData = await promisedFileReader(pdfFile)
    if (this.state.pdfData !== fileData)
      this.setState({ pdfData: fileData, pdfObjectUrl, contentMode: 'pdf' })
    else
      this.setState({ ready: true, contentMode: 'pdf' })
  }
  pdfViewerLoaded = () =>
    this.setState({ ready: true })

  render = () => (
    <div id={'customContentEditorContainer'} className={'customContentEditorContainer' + (this.state.showing ? ' customContentEditorContainerShow' : '')}>
      <input
        className="customContentEditorTitle"
        type="text"
        name="title"
        autoComplete="off"
        placeholder={lang.CUSTOM_CONTENT_EDITOR_TITLE_PLACEHOLDER}
        value={this.state.title}
        onChange={this.handleChange}
        disabled={!this.state.ready}
      />
      <div className={'customContentEditorTinyContainer'}>
        {!this.state.ready ?
          <div className={'customContentEditorLoadingSpinnerContainer'}>
            <Spinner className={'customContentEditorLoadingSpinner'} />
            {lang.LOADING}
          </div>
          : ''
        }
        {this.state.contentMode === 'text' ?
          <Editor
            tinymceScriptSrc={'/tinymce/tinymce.min.js'}
            onInit={(evt, editor) => this.setState({ editor })}
            initialValue={this.state.initialContent}
            init={{
              height: "100%",
              width: "100%",
              language: "es_ES",
              branding: false,
              plugins: [
                'advlist lists autolink link table paste'
              ],
              advlist_number_styles: 'default,lower-alpha,upper-alpha,lower-roman,upper-roman',
              paste_enable_default_filters: false,
              paste_data_images: true,
              //paste_preprocess: this.pastePreprocess,
              toolbar_mode: 'wrap',
              mobile: {
                menubar: true
              },
              toolbar: 'fromPdfFile | undo redo | formatselect | ' +
                'bold italic underline forecolor backcolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat',
              menubar: 'file edit insert format',
              menu: {
                file: { title: 'File', items: 'newdocument' },
                edit: { title: 'Edit', items: 'undo redo | cut copy paste pastetext | selectall | searchreplace' },
                insert: { title: 'Insert', items: 'link' },
                format: {
                  title: 'Format',
                  items: 'bold italic underline strikethrough superscript subscript | formats blockformats fontformats fontsizes align lineheight | forecolor backcolor | removeformat'
                },
              },

              setup: (editor) => {
                if (this.state.initialTitle) return null
                editor.ui.registry.addButton('fromPdfFile', {
                  text: lang.CUSTOM_CONTENT_EDITOR_UPLOAD_FILE_PDF_BUTTON,
                  icon: 'new-document',
                  onAction: this.toggleFilePicker
                });
              }
            }}
          />
          : ''
        }
        {this.state.contentMode === 'pdf' ?
          <PdfViewer
            show={this.state.ready}
            objectUrl={this.state.pdfObjectUrl || this.state.initialContent}
            onLoad={this.pdfViewerLoaded}
          />
          : ''
        }
      </div>
      <div className={'customContentEditorButtonbar'}>
        <button className={'rcl rclSecondaryDark'} onClick={this.close}>
          {lang.CUSTOM_CONTENT_EDITOR_CANCEL}
        </button>
        {this.state.contentMode === 'pdf' ?
          <button className={'rcl rclSecondaryDark'} onClick={this.toggleFilePicker}>
            {lang.CUSTOM_CONTENT_EDITOR_PDF_CHANGE}
          </button>
          : ''
        }
        <button className={'rcl'} onClick={this.save}>
          {lang.CUSTOM_CONTENT_EDITOR_SAVE}
        </button>
      </div>

      <ModalFilePicker
        message={lang.CUSTOM_CONTENT_EDITOR_FILE_PICKER_TEXT}
        show={this.state.showFilePicker}
        maxSize={config.CUSTOM_CONTENT_MAX_FILE_SIZE}
        extensions={config.CUSTOM_CONTENT_EXTENSIONS}
        fileTypes={config.CUSTOM_CONTENT_FILE_TYPES}
        close={this.toggleFilePicker}
        cb={this.uploadPdfFile}
      />
    </div>
  )
}

export default CustomContentEditor