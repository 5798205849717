import React from "react"

import PdfViewer from "./PdfViewer"
import Spinner from "./Spinner"
import pdfDownload from "../actions/pdfDownload"

import lang from "../lang/es"
import './PlayerPdfViewer.css'

class PlayerPdfViewer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      ready: false,
      objectUrl: null
    }
  }

  componentDidMount = () => {
    this.downloadPdf()
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.pdf !== this.props.pdf) this.downloadPdf()
  }

  componentWillUnmount = () => {
    if (this.state.objectUrl) URL.revokeObjectURL(this.state.objectUrl)
  }

  downloadPdf = async () => {
    const data = await pdfDownload(this.props.pdf)
    if (this.state.objectUrl) URL.revokeObjectURL(this.state.objectUrl)
    this.setState({objectUrl: URL.createObjectURL(data)})
  }

  pdfViewerLoaded = () => {
    this.setState({ ready: true })
  }

  render = () => (
    <div className={`playerPdfViewerContainer ${this.props.className ? this.props.className : ''}`}>
      {!this.state.ready ?
        <div className={'playerPdfViewerLoadingSpinnerContainer'}>
          <Spinner className={'playerPdfViewerLoadingSpinner'} />
          {lang.LOADING}
        </div>
        : ''
      }
      {
        <PdfViewer
          show={this.state.ready}
          objectUrl={this.state.objectUrl}
          onLoad={this.pdfViewerLoaded}
        />
      }
    </div>
  )
}

export default PlayerPdfViewer