import React from "react"

import PlayerPlaylistLawContent from "./PlayerPlaylistLawContent"
import './PlayerPlaylistLaw.css'
import Spinner from "./Spinner"
import lang from "../lang/es"
import {getLawList} from "../actions/law"

class PlayerPlaylistLaw extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      lawList: null,
      purchasedLaws: null,
      currentLaw: null
    }
  }

  componentDidMount = () => {
    if (!this.state.lawList) getLawList(this.setLawList)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.currentLaws.length !== this.props.currentLaws.length)
      this.setLawList(this.state.lawList)
  }

  setLawList = (lawList) => {
    let purchasedLaws = {}
    try {
      this.props.currentLaws.forEach((law) => {
        if (lawList[law]) purchasedLaws[law] = lawList[law].name
      })
    } catch(e) {
      console.error(e)
      purchasedLaws = {}
    }

    this.setState({lawList, purchasedLaws})
  }

  setLaw = (currentLaw) => {
    this.setState({ currentLaw })
  }

  unsetLaw = () => this.setState({currentLaw: null})

  render = () => (
    <div id={'playerPlaylistLawContainer'}>
      { !this.state.purchasedLaws ?
        <div className={'playerPlaylistLoading'}>
          <Spinner className={'playerPlaylistLoadingSpinner'}/>
          { lang.LOADING }
        </div>
        :
        !this.state.currentLaw ?
          <div className={'playerPlaylistLawListContainer'}>
            <ul className={'playerPlaylistLawList'}>
              {
                Object.keys(this.state.purchasedLaws).map(
                  (lawId,index) =>
                    <li key={index}>
                      <button className={'playerPlaylistLawLawButton'} onClick={() => this.setLaw(lawId)}>
                        { this.state.purchasedLaws[lawId] }
                      </button>
                    </li>
                )
              }
            </ul>
            <button className={'rcl playerPlaylistLawAddLawButton'} onClick={this.props.toggleShowLawPurchase}>
              {lang.PLAYLIST_LAW_ADD_LAW_BUTTON}
            </button>
          </div>
          :
          <PlayerPlaylistLawContent
            lawId={this.state.currentLaw}
            playTrack={this.props.playTrack}
            unsetLaw={this.unsetLaw}
          />
      }
    </div>
  )
}

export default PlayerPlaylistLaw