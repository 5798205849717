import React from "react"

import ModalOptionsBinary from "./ModalOptionsBinary"
import lang from "../lang/es"

class Confirm extends React.Component {
  render = () => (
    <ModalOptionsBinary
    message={this.props.message}
      options={[lang.CONFIRM_NO, lang.CONFIRM_YES]}
      cb={this.props.cb}
      close={this.props.closeConfirm}
    />
  )
}

export default Confirm