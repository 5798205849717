import React from "react"

import './Modal.css'

class Modal extends React.Component {
  render = () => (
    <div className={`modalContainer ${this.props.show ? '' : ' modalContainerDisabled'} ${this.props.className ? this.props.className : ''}`}>
      <div className={`modalContainerCard ${this.props.show ? '' : 'modalContainerCardDisabled '}`}>
        <div className={'modalTitle'}>{this.props.title}</div>
        <div className={'modalClose'} onClick={this.props.close}>✕</div>
        { this.props.children }
      </div>
    </div>
  )
}

export default Modal