import React from "react"
import { ReactComponent as LogoIconSvg } from '../assets/logo.svg'
import './LogoIcon.css'

class LogoIcon extends React.Component {
  render = () => (
    <div className={'logoIconContainer'}>
        <LogoIconSvg className={ 'logoIcon' }/>
    </div>
  )
}

export default LogoIcon