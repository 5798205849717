import React from "react"
import Logo from './Logo'
import { setNewPassword } from '../actions/password'
import config from '../config.js'

import lang from '../lang/es'
import './UpdatePassword.css'

const isTokenExpired = (token) => {
  try {
    const exp = window.atob(token.split('.')[1]).exp
    return parseInt(exp)*1000 < Date.now()
  } catch(e) {
    return true
  }
}

class UpdatePassword extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      passwordConfirm: '',
      expired: isTokenExpired(this.props.updatePasswordToken),
      success: false,
      error: false,
      lengthError: false,
      working: false
    }
  }

  handlePasswordChange = (event) => this.setState({ password: event.target.value, error: false, lengthError: event.target.value.length < config.MIN_PASS_LENGTH })
  handlePasswordConfirmChange = (event) => this.setState({ passwordConfirm: event.target.value, error: false })

  componentDidUpdate = (prevProps) => {
    if (prevProps.updatePasswordToken !== this.props.updatePasswordToken) {
      this.setState({
        password: '',
        passwordConfirm: '',
        expired: isTokenExpired(this.props.updatePasswordToken),
        success: false,
        error: false,
        lengthError: false,
        working: false
      })
    }
  }

  passwordRestoreResponse = (result) => {
    // Reset login cookie if success
    if (result) this.props.delAuthToken()

    // Show success or error message based on result
    this.setState({
      working: false,
      success: result,
      error: !result,
      lengthError: false
    })
  }

  requestPasswordRestore = (e) => {
    e.preventDefault()
    if (this.state.password.length >= config.MIN_PASS_LENGTH) {
      this.setState({ working: true })
      setNewPassword(this.state.password, this.props.updatePasswordToken, this.passwordRestoreResponse)
    } else this.setState({ lengthError: true })
  }

  notification = () => { // Order matters: success > expired
    let msg, style
    if (this.state.success) {
      msg = lang.UPDATE_PASSWORD_SUCCESS
      style = ' updatePasswordSuccess'
    } else if (this.state.expired) {
      msg = lang.UPDATE_PASSWORD_EXPIRED_LINK_ERROR
      style = ' updatePasswordExpired'
    }
    return (
      <div className={ 'cardMessage updatePasswordNotification' + style }>
        <div className={ 'updatePasswordNotificationMsg' }> { msg } </div>
        <button type={'button'} className="rcl" onClick={ () => window.location = window.location.origin }>
          { lang.BACK_BUTTON }
        </button>
      </div>
    )
  }

  form = () => {
    return(
      <form className={'updatePasswordFormContainer centeredCard'}>
        <input className="cardInput" type="password" name="password" autoComplete="new-password" id="new-password" placeholder={ lang.HINT_NEW_PASSWORD } required={true}
               value={ this.state.password } minLength={ config.MIN_PASS_LENGTH } onChange={ this.handlePasswordChange }/>
        <input className="cardInput" type="password" name="passwordConfirm" autoComplete="new-password" id="new-password" placeholder={ lang.HINT_NEW_PASSWORD_CONFIRM } required={true}
               value={ this.state.passwordConfirm } minLength={ config.MIN_PASS_LENGTH } onChange={ this.handlePasswordConfirmChange }/>
        <div
          className={ 'cardMessage updatePasswordError updatePasswordErrorShow' }>
          { this.state.passwordConfirm && this.state.password !== this.state.passwordConfirm ? lang.UPDATE_PASSWORD_ERROR_CHECK_FAILED : '' }
          { this.state.error ? lang.UPDATE_PASSWORD_GENERIC_ERROR : '' }
          { this.state.lengthError ? lang.UPDATE_PASSWORD_ERROR_LENGTH : '' }
        </div>
        { this.state.working ?
          <div className="updatePasswordLoading"> { lang.UPDATE_PASSWORD_LOADING } </div>
          :
          <input type={'submit'} className="rcl" onClick={ this.requestPasswordRestore } value={ lang.PASSWORD_RESTORE_BUTTON } />
        }
        <button type={'button'} className="rcl rclSecondary updatePasswordBackButton" onClick={ this.props.cancelUpdatePassword }>
          { lang.CANCEL_AND_BACK_BUTTON }
        </button>
      </form>
    )
  }

  render = () => (
    <div id={'updatePasswordContainer'} className={ this.props.updatePasswordToken ? 'showUpdatePassword fullScreenModal' : 'hideUpdatePassword fullScreenModal' }>
      <div className='centeredCard updatePasswordCard'>
        <Logo />
        { this.state.expired || this.state.success ? this.notification() : this.form() }
      </div>
    </div>
  )
}

export default UpdatePassword