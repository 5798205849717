import config from "../config"
import session from '../utils/session.js'

const selectLaw = (lawId, cb) => {
  fetch(`${config.HTTP_PROTOCOL}://${config.RECITALEYES_LOGIN_URL}/setLawPurchase/${lawId}`,
    { headers: { "Authorization": `Bearer ${session.get()}` } })
    .then((res) => {
      if (res.ok) res.text().then(token => {
        cb(token)
      })
      else cb(null)
    })
    .catch(() => cb(null))
}

export default selectLaw