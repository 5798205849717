import React from "react"
import AudioPlayer, { RHAP_UI } from 'react-h5-audio-player'
import 'react-h5-audio-player/lib/styles.css'

import { ReactComponent as RabbitIcon } from '../assets/rabbit.svg'
import { ReactComponent as TortoiseIcon } from '../assets/tortoise.svg'

import './PlayerAudio.css'
import {toast} from "react-toastify"
import lang from "../lang/es"

class PlayerAudio extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playbackRate: props.playbackRate
    }
  }

  audioElement = React.createRef()

  setPlaybackRate = () => {
    this.audioElement.current.audio.current.playbackRate = this.props.playbackRate
  }

  componentDidMount = () => this.setPlaybackRate()
  componentDidUpdate = () => {
    this.setPlaybackRate()

    // Set custom start point if provided
    if (this.props.startPoint > 0)
      this.audioElement.current.audio.current.currentTime = this.props.startPoint

    if (!this.props.canPlay) this.audioElement.current.audio.current.pause()
  }

  audioTokenPlayError = (e) => {
    if (this.props.src.endsWith('no-subscription')) {
      toast.error(lang.NO_SUBSCRIPTION_PLAYER_ERROR, {
        position: "top-center",
        autoClose: 7000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        //onClick: this.props.toggleSubscription
      })
    }
    if (this.props.src !== '') {
      console.error('Error loading file. Audio token may have expired.')
      console.error(e)

      this.props.retryTrack(this.audioElement.current.audio.current.currentTime)
    }
  }

  extraControls = () => {
    const controls = [
      RHAP_UI.LOOP,
      <TortoiseIcon className={'playbackIcon'} onClick={() => this.props.playbackRateControl(-0.1)} />,
      <span className={'playbackRateLabel'}>{ this.props.playbackRate.toFixed(1) }</span>,
      <RabbitIcon className={'playbackIcon'} onClick={() => this.props.playbackRateControl(0.1)} />
    ]

    return controls
  }

  render = () => (
    <AudioPlayer
      ref={ this.audioElement }
      src={ this.props.src }
      autoPlay={ true }
      autoPlayAfterSrcChange={ true }
      customVolumeControls={ window.innerWidth < 400 ? [] : [ RHAP_UI.VOLUME ] }
      showJumpControls={ true }
      progressJumpSteps={{ backward: 10000, forward: 10000 }}
      showSkipControls={ true }
      onClickPrevious={ this.props.prev }
      onClickNext={ this.props.next }
      onEnded={ this.props.next }
      onError={ this.audioTokenPlayError }
      customAdditionalControls={ this.extraControls() }
      className={this.props.className}
    />
  )
}

export default PlayerAudio