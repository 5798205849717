const config = {
  HTTP_PROTOCOL: process.env.REACT_APP_HTTP_PROTOCOL,
  RECITALEYES_BASE_URL: process.env.REACT_APP_RECITALEYES_BASE_URL, // Used to set the cookie for all Recitaleyes env
  RECITALEYES_APP_URL: process.env.REACT_APP_RECITALEYES_APP_URL, // Used for capacitor deep links
  RECITALEYES_API_URL: process.env.REACT_APP_RECITALEYES_API_URL,
  RECITALEYES_LOGIN_URL: process.env.REACT_APP_RECITALEYES_AUTH_URL,
  PAYPAL_CLIENT_ID: process.env.REACT_APP_PAYPAL_CLIENT_ID,

  CONTACT_EMAIL: 'hola@recitaleyes.com',
  MAX_CONTACT_MESSAGE_LENGTH: 5000,

  MIN_PLAYBACK_RATE: 0.5,
  MAX_PLAYBACK_RATE: 5,

  MAX_AUDIO_RETRIES: 3,

  MIN_PASS_LENGTH: 8,

  CHARACTERS_PER_PAGE: 3000,

  UPDATE_CHECK_INTERVAL: 1000*60*30, //30min in ms
  //TOKEN_AUTO_RENEW_INTERVAL: 1000*60*60*2, // 2h in ms
  TOKEN_AUTO_RENEW_INTERVAL: 1000*60*60*2, // 2h in ms

  THEMES: [
    'classic',
    'chocoleyes'
  ],

  CUSTOM_CONTENT_MAX_FILE_SIZE: 6*1024*1024, //bytes. 6mb
  CUSTOM_CONTENT_EXTENSIONS: ['.pdf'],
  CUSTOM_CONTENT_FILE_TYPES: ['application/pdf'],

  AWS_EDSTART_PROMO: true
}

export default config