import React from "react"
import LogoIcon from './LogoIcon.js'
import { ReactComponent as LogoTextSvg } from '../assets/logo_label.svg'
import './Logo.css'

class Logo extends React.Component {
  render = () => (
    <div id={'logoContainer'}>
        <LogoIcon />
        <LogoTextSvg className={'logoLabel'}/>
    </div>
  )
}

export default Logo