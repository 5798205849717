import React from "react"
import lang from '../lang/es'
import config from "../config"

import Modal from './Modal'
import Spinner from './Spinner'
import { ReactComponent as AlertCircleIcon } from '../assets/alert-circle.svg'

import selectLaw from "../actions/lawSelector"

import './LawSelector.css'

const dateToString = (date) => {
  return `${date.getDate()} de ${lang.MONTHS[date.getMonth()]} de ${date.getFullYear()}`
}

const dateConverter = (sd) => {
  const d = sd.toString()
  const df = new Date(`${d.slice(0, 4)}-${d.slice(4, 6)}-${d.slice(6, 8)}`)
  return dateToString(df)
}

class LawSelector extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: this.props.show,
      filter: '',
      message: null
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps && prevProps.show !== this.props.show) this.setState({ filter: '', message: null })
  }

  handleFilterChange = (event) => this.setState({ filter: event.target.value })

  lawLabel = (version, date) => {
    if (Number.isInteger(parseInt(version))) {
      // Consolidated
      return `${ lang.LAW_SELECTOR_LABEL_CONSOLIDATED } ${ dateConverter(version) }`
    } else {
      // Others (dof, hash, etc.)
      return lang.LAW_SELECTOR_LABEL_UPDATED.replace('%1', dateToString(new Date(date)))
    }
  }

  purchased = () =>
    <div className={'purchasedLawDone'}>
      <div className={'okLogo'}>✓</div>
      <div className={'purchasedLawDoneMsg'}>
        {lang.LAW_PURCHASE_DONE_OK}
        <button className={'rcl purchasedLawPurchaseMore'} onClick={this.props.close}>
          {lang.LAW_PURCHASE_DONE_CLOSE}
        </button>
      </div>
    </div>
  errorWhilePurchasing = () =>
    this.error(
      <div>
        {lang.LAW_PURCHASE_ERROR_FROM_SERVER}
        <a href={`mailto:${config.CONTACT_EMAIL}`}>{config.CONTACT_EMAIL}</a>.
      </div>
    )
  lawPurchased = (token) => {
    if (token) {
      this.props.setAuthToken(token)
      this.setState({ message: this.purchased })
    } else {
      this.setState({ message: this.errorWhilePurchasing })
    }
  }

  purchasing = () =>
    <div className={'purchasedLawDone purchasedLawDoneSpinner'}>
      <Spinner className={'purchasedLawSpinner'} />
      <div className={'purchasedLawSpinnerText'}>{lang.LAW_PURCHASE_LOADING_MESSAGE}</div>
    </div>

  purchase = (lawId) => {
    this.props.setConfirm(
      lang.LAW_PURCHASE_CONFIRM.replace(
        '%1',
        `"${this.props.lawList[lawId].name.substring(0, 50)}..."`
      ),
      (res) => {
        if (res) {
          this.setState({ message: this.purchasing })
          selectLaw(lawId, this.lawPurchased)
        }
      }
    )
  }

  error = (msg) =>
    <div className={'purchasedLawDone'}>
      <AlertCircleIcon className={'koIcon'} />
      <div className={'purchasedLawDoneMsg'}>
        {msg}
      </div>
    </div>

  render = () => (
    <Modal
      show={this.props.show}
      close={this.props.close}
      title={lang.LAW_PURCHASE_MODAL_TITLE}
    >
      {!this.state.message ?
        <div className={'lawPurchaseContent'}>
          <ul className={'lawPurchaseList'}>
            {
              this.props.lawList ?
                Object.keys(this.props.lawList).map(
                  (lawId, index) =>
                    this.props.lawList[lawId].name.toLowerCase().includes(this.state.filter.toLowerCase()) //filter
                      && !this.props.currentLaws.includes(lawId) // exclude already purchased laws
                      ?
                      <li key={index}>
                        <button className={'lawPurchaserLawListButton'} onClick={() => this.purchase(lawId)}>
                          <div className={'lawPurchaserLawName'}>{this.props.lawList[lawId].name}</div>
                          <div className={'lawPurchaserLawDate'}>
                            {this.lawLabel(this.props.lawList[lawId].ver, this.props.lawList[lawId].date)}
                          </div>
                        </button>
                      </li> : ''
                ) :
                lang.LAW_PURCHASE_NO_LAWS_AVAILABLE
            }
            <li key={'requestLaw'} className={'lawPurchaserContact'}>
              ¿No encuentras alguna norma? ¡Pídenosla pulsando <button
                className={'rcl rclSecondary lawPurchaserOpenContact'} onClick={this.props.toggleContact}>AQUÍ</button> y
              trataremos de tenerla disponible <strong>en menos de 72h</strong>!
            </li>
          </ul>
          <input
            className="cardInput lawPurchaseInputFilter"
            type="text"
            name="filter"
            placeholder={lang.LAW_PURCHASE_FILTER_PLACEHOLDER}
            value={this.state.filter}
            onChange={this.handleFilterChange}
          />
        </div>
        :
        <div className={'lawPurchaseMsgContainer'}>
          {this.state.message()}
        </div>
      }
    </Modal>
  )
}

export default LawSelector