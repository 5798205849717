import React from "react"

import delCustomer from "../actions/delCustomer"

import Modal from './Modal'
import { ReactComponent as AccountIcon } from "../assets/account-circle.svg"
import { ReactComponent as ChangePasswordIcon } from "../assets/lock-reset.svg"
import { ReactComponent as DeleteIcon } from "../assets/delete.svg"

import lang from "../lang/es"

import './MyAccount.css'
import { toast } from "react-toastify"

class MyAccount extends React.Component {

  deleteAccountConfirm = () => {
    this.props.setConfirm(
      lang.MY_ACCOUNT_DELETE_ACCOUNT_CONFIRMATION,
      (res) => { if (res) this.deleteAccount() }
    )
  }

  deleteAccount = () => {
    toast.info(lang.MY_ACCOUNT_DELETE_ACCOUNT_REQUESTING, {
      position: "top-center",
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });

    delCustomer(
      (yes) => {
        if (yes) {
          toast.error(lang.MY_ACCOUNT_DELETE_ACCOUNT_REQUESTED, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        } else {
          toast.error(lang.MY_ACCOUNT_DELETE_ACCOUNT_REQUEST_ERROR, {
            position: "top-center",
            autoClose: 6000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          })
        }
      }
    )
  }

  render = () =>
    <Modal
      show={this.props.show}
      title={lang.MY_ACCOUNT_TITLE}
      close={this.props.close}
    >
      <AccountIcon className={'myAccountIcon'} />
      <div className="myAccountData">
        <span className="myAccountLabel">{lang.MY_ACCOUNT_EMAIL}</span>
        <span className="myAccountValue">{this.props.currentUser}</span>
      </div>
      <div className="myAccountActions">
        <button className={'rcl rclSecondary myAccountActionsButton'} onClick={this.props.togglePasswordRestore}>
          <ChangePasswordIcon className="myAccountActionsButtonIcon" />
          {lang.MY_ACCOUNT_CHANGE_PASSWORD}
        </button>
        <button className={'rcl rclSecondary myAccountActionsButton myAccountActionsDeleteButton'} onClick={this.deleteAccountConfirm}>
          <DeleteIcon className="myAccountActionsButtonIcon myAccountActionsButtonIconDelete" />
          {lang.MY_ACCOUNT_DELETE_ACCOUNT}
        </button>
      </div>
    </Modal>
}

export default MyAccount