import React from "react"
import Logo from './Logo'

import getAuthCookie from '../actions/login'
import lang from '../lang/es'
import './Login.css'

class Login extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: '',
      pass: '',
      error: false,
      working: false
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.show !== this.props.show && !this.props.show)
      this.setState({ user: '', pass: '', error: false, working: false })
  }

  handleUserChange = (event) => this.setState({ user: event.target.value })
  handlePassChange = (event) => this.setState({ pass: event.target.value })

  receiveAuthToken = (token) => {
    if (token) this.props.setAuthToken(token, true)
    else this.setState({ error: true, working: false })
  }

  requestAuthToken = (e) => {
    e.preventDefault()
    if (this.state.user && this.state.pass) {
      this.setState({ working: true })
      getAuthCookie(this.state.user, this.state.pass, this.receiveAuthToken)
    }
  }

  render = () => (
    <form id={'loginContainer'} className={ this.props.show ? 'showLogin fullScreenModal' : 'hideLogin fullScreenModal' }>
      <div className='centeredCard loginCard'>
        <Logo />
        <input className="cardInput" type="email" name="email" autoComplete="username" placeholder={ lang.HINT_EMAIL } required={true} value={ this.state.user } onChange={ this.handleUserChange }/>
        <input className="cardInput" type="password" name="password" autoComplete="current-password" id="current-password" placeholder={ lang.HINT_PASSWORD } required={true} value={ this.state.pass } onChange={ this.handlePassChange }/>
        <div className={ this.state.error ? 'cardMessage loginError loginErrorShow' : 'cardMessage loginError' }>{ lang.LOGIN_ERROR }</div>
        <button type={'button'} onClick={ this.props.togglePasswordRestore } className={ 'loginResetPassword' }>{ lang.LOGIN_RESET_PASSWORD }</button>
        { this.state.working ?
          <div className="loginLoading"> { lang.LOGGING_IN } </div>
          :
          <input type={'submit'} className="rcl" onClick={this.requestAuthToken} value={ lang.LOGIN_BUTTON } />
        }
        <button type={'button'} className="rcl rclSecondary loginSignUpButton" onClick={this.props.toggleSignUp}>
          { lang.LOGIN_SIGNUP }
        </button>
      </div>
    </form>
  )
}

export default Login