import config from '../config.js'

const requestPasswordRestoreEmail = (email, cb) => {
  const opts = {
    method: 'post',
    body: JSON.stringify({ email })
  }

  fetch(`${config.HTTP_PROTOCOL}://${config.RECITALEYES_LOGIN_URL}/getPassToken`, opts)
    .then((res) => {
      if (res.ok) cb(true)
      else cb(false)
    })
    .catch(cb(false))
}

const setNewPassword = (password, updatePasswordToken, cb) => {
  const opts = {
    method: 'post',
    body: JSON.stringify({ auth: updatePasswordToken, pass: password })
  }

  fetch(`${config.HTTP_PROTOCOL}://${config.RECITALEYES_LOGIN_URL}/setPass`, opts)
    .then((res) => {
      if (res.ok) cb(true)
      else cb(false)
    })
    .catch(cb(false))
}

export { requestPasswordRestoreEmail, setNewPassword }