import config from "../config"
import session from '../utils/session.js'

const getCustomPlaylist = (cb) => {
  fetch(`${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/custom/list`,
    { headers: { "Authorization": `Bearer ${session.get()}` } }
  )
    .then(res => res.json())
    .then(json => cb(json))
}

const getCustomTrack = (id, textOnly, cb) => {
  const url = `${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/custom/get/${id}${textOnly ? '/text' : ''}`
  fetch(url,
    { headers: { "Authorization": `Bearer ${session.get()}` } }
  )
    .then(res => res.json())
    .then(json => cb({
        text: json,
        audioUrl: json["audio"]
      })
    )
}

const setCustomTrack = (id, content, cb) => {
  const opts = {
    method: 'post',
    headers: { "Authorization": `Bearer ${session.get()}` },
    body: JSON.stringify(content)
  }

  const endpoint = !id ?
    `${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/custom/add` :
    `${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/custom/edit/${id}`

  fetch(endpoint, opts)
    .then((res) => {
      if (res.ok) res.text().then((txt) => cb(txt))
      else cb(false)
    })
}

const setCustomContentPurchase = (id, cb) => {
  fetch(
    `${config.HTTP_PROTOCOL}://${config.RECITALEYES_LOGIN_URL}/setCustomContentPurchase/${id}`,
    { headers: { "Authorization": `Bearer ${session.get()}` } }
  ).then((res) => {
      if (res.ok) cb(true)
      else if (res.status === 402) cb(false, true)
      else cb(false)
    })
}

const deleteCustomContent = (id, cb) => {
  fetch(
    `${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/custom/delete/${id}`,
    { headers: { "Authorization": `Bearer ${session.get()}` } }
  ).then((res) => {
    if (res.ok) cb(true)
    else cb(false)
  })
}

export { getCustomPlaylist, getCustomTrack, setCustomTrack, setCustomContentPurchase, deleteCustomContent }