import config from '../config.js'
import session from '../utils/session.js'

const getLawList = (cb) => {
  fetch(`${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/lawList`,
    { headers: { "Authorization": `Bearer ${session.get()}` } }
  )
    .then(res => res.json())
    .then(json => cb(json))
}

const getLawPlaylist = (lawId, cb) => {
  fetch(`${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/playlist/${lawId}`,
    { headers: { "Authorization": `Bearer ${session.get()}` } }
    )
    .then(res => res.json())
    .then(json => cb(json))
}

const getLawTrack = (lawId, track, cb) => {
  const url = `${config.HTTP_PROTOCOL}://${config.RECITALEYES_API_URL}/law/${lawId}/${track}/text`
  fetch(url,
    { headers: { "Authorization": `Bearer ${session.get()}` } }
    )
    .then(res => res.json())
    .then(json => cb({
        text: json,
        audioUrl: json["audio"]
      })
    )
}


export { getLawList, getLawPlaylist, getLawTrack }