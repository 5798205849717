import React from "react"

import lang from '../lang/es'
import './PlayerPlaylistLawContent.css'
import {getLawPlaylist, getLawTrack} from "../actions/law"
import { renderToStaticMarkup } from "react-dom/server"

class PlayerPlaylistLawContent extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playlist: [],
      track: 0
    }
  }

  componentDidMount = () => {
    getLawPlaylist(this.props.lawId, this.setPlaylist)
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps.lawId !== this.props.lawId)
      getLawPlaylist(this.props.lawId, this.setPlaylist)
  }

  setPlaylist = (playlist) => {
    this.setState({ playlist })
    this.setTrack(0, 0) // Play first article from the start
  }

  setTrack = (track, start) => {
    getLawTrack(
      this.props.lawId,
      track,
      (playable) => this.playTrack(playable, start || 0)
    ) // Autostart first article
    this.setState({ track })
  }

  jump = (j) => {
    const nextTrack = this.state.track + j
    if (nextTrack > -1 && nextTrack < this.state.playlist.length)
      this.setTrack(nextTrack)
  }

  textToHtml = (text) => {
    if (!text) return
    if (typeof text === 'string') text = text.split('\n') // compatibility mode for old LawIndex style (text as multi-line-string instead array)
    return renderToStaticMarkup(
      <div>{
        text.map(
          (item, index) => <span className={'lawTextParagraph'} key={index}>{item}</span>
        )
      }</div>
    )
  }
  containerizePlainHtml = (html, sourceType) => {
    return `<div class="${sourceType}">${html.join('')}</div>`.toString()
  }

  playTrack = (playable, start) => {
    this.props.playTrack(
      {
        discId: this.props.lawId,
        track: this.state.track,
        title: playable.text.title,
        subtitle: playable.text.subtitle,
        html: playable.text.html ?
          this.containerizePlainHtml(playable.text.html, playable.text.sourceType):
          this.textToHtml(playable.text.text),
        audioUrl: playable.audioUrl,
        navUrl: this.genNavUrl(this.props.lawId, this.state.track),
        retry: (start) => this.setTrack(this.state.track, start),
        next: () => this.jump(1),
        prev: () => this.jump(-1)
      },
      start
    )
  }

  genNavUrl = (lawId, track) => `#law=${lawId}&track=${track}`

  render = () => (
    <div id={'playerLawPlaylist'}>
      <ul className='lawPlaylistList'>
        { this.state.playlist.map(
            (content,track) =>
              <li key={track}>
                <a
                  href={this.genNavUrl(this.props.lawId, track)}
                  className={this.state.track === track ? 'track currentTrack lawPlaylistListTrack' : 'track lawPlaylistListTrack'}
                  onClick={() => this.setTrack(track)}
                >
                  {typeof content === "string" ? content :
                    <div className={'lawPlaylistListTrackContainer'}>
                      <div className={'lawPlaylistListTrackTitle'}>{content.title}</div>
                      <div className={'lawPlaylistListTrackSubtitle'}>{content.subtitle}</div>
                    </div>
                  }
                </a>
              </li>
          )
        }
      </ul>
      <button className={'rcl playerPlaylistLawContentBackButton'} onClick={this.props.unsetLaw}>
        {lang.PLAYLIST_LAW_BACK_BUTTON}
      </button>
    </div>
  )
}

export default PlayerPlaylistLawContent