import React from "react"

import './Prompt.css'
import lang from "../lang/es"

class Prompt extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      coupon: ''
    }
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  answer = (res) => {
    this.setState({coupon: ''})
    this.props.cb(res)
    this.props.closePrompt()
  }

  render = () => (
    <div className={ `modalOptionsContainer${this.props.message ? '' : ' modalOptionsContainerHide'}`}>
      <div className={ `modalOptionsBox${this.props.message ? '' : ' modalOptionsBoxHide'}`}>
        <div className={'modalOptionsMessage'}>
          { this.props.message }
        </div>
        <input className={''} type="text" name="coupon" autoComplete="off" id="coupon"
               placeholder={ this.props.hint } required={true}
               value={ this.state.coupon } onChange={ this.handleChange }/>
        <div className={'modalOptionsButtonBar'}>
          <button className={'rcl rclSecondaryDark'} onClick={ () => this.answer(false) }>
            {lang.SUBSCRIPTION_COUPON_INPUT_CANCEL}
          </button>
          <button className={'rcl'} onClick={ () => this.answer(this.state.coupon) }>
            {lang.SUBSCRIPTION_COUPON_INPUT_ACTIVATE}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Prompt