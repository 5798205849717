import session from './session.js'

const getCookie = () => {
  const authCookie = session.get()
  if (authCookie) {
    if (JSON.parse(atob(authCookie.split('.')[1])).exp > Math.trunc(Date.now() / 1000))
      return authCookie
    else {
      console.error('ERROR: auth token saved in session storage is expired.')
      delCookie()
    }
  }
  return null
}

const delCookie = () => {
  session.del()
}

const setCookie = (cookie) => {
  session.set(cookie)
}

const cookieManager = {
  set: setCookie,
  get: getCookie,
  del: delCookie
}

export default cookieManager