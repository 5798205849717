import React from "react"
import Logo from "./Logo"
import './SignUp.css'

import lang from "../lang/es"
import signUp from "../actions/signup"
import config from "../config"

class SignUp extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      name: '',
      surname: '',
      email: '',
      error: false,
      success: false,
      working: false
    }
  }

  handleChange = (e) => this.setState({ [e.target.name]: e.target.value })

  close = () => this.props.toggleSignUp()

  signUpRequest = (e) => {
    e.preventDefault()

    if (!this.state.name)
      return this.setState({ error: lang.SIGNUP_ERROR_NAME })
    if (!this.state.surname)
      return this.setState({ error: lang.SIGNUP_ERROR_SURNAME })
    if (!/\S+@\S+\.\S+/.test(this.state.email))
      return this.setState({ error: lang.SIGNUP_ERROR_EMAIL })

    signUp(
      this.state.email,
      this.state.name,
      this.state.surname,
      this.signUpResponse
    )
    this.setState({ working: true })
  }

  signUpResponse = (res) => {
    this.setState({
      working: false,
      success: !!res,
      error: res ? false : lang.SIGNUP_ERROR
    })
  }

  render = () => (
    <div id={'signUpContainer'} className={this.props.show ? 'showSignUp fullScreenModal' : 'hideSignUp fullScreenModal'}>
      <div className='centeredCard signUpCard'>
        <Logo />
        {!this.state.success ?
          <form className="signUpFormContainer">
            <input className="cardInput" type="text" name="name" autoComplete="given-name" id="given-name" placeholder={lang.HINT_NAME} required={true} value={this.state.name} onChange={this.handleChange} />
            <input className="cardInput" type="text" name="surname" autoComplete="family-name" id="family-name" placeholder={lang.HINT_SURNAME} required={true} value={this.state.surname} onChange={this.handleChange} />
            <input className="cardInput" type="email" name="email" autoComplete="username" placeholder={lang.HINT_EMAIL} required={true} value={this.state.email} onChange={this.handleChange} />
            <div className={this.state.error ? 'cardMessage signUpError signUpErrorShow' : 'cardMessage signUpError'}>{this.state.error}</div>
            {this.state.working ?
              <div className="loginLoading"> {lang.SIGNUP_LOADING} </div>
              :
              <input type={'submit'} className="rcl" onClick={this.signUpRequest} value={lang.SIGNUP_BUTTON} />
            }
            <button type={'button'} className="rcl rclSecondary" onClick={this.close}>
              {lang.SIGNUP_ALREADY_REGISTERED}
            </button>
            <div className='signUpLegal'>
              {lang.LEGAL_LOPD_FIRST_LAYER}
              <a href={`${config.HTTP_PROTOCOL}://${config.RECITALEYES_BASE_URL}/privacy.pdf`}>{lang.LEGAL_LOPD}</a>.
              {lang.LEGAL_TOS_FIRST_LAYER}
              <a href={`${config.HTTP_PROTOCOL}://${config.RECITALEYES_BASE_URL}/tos.pdf`}>{lang.LEGAL_TOS}</a>.
            </div>
          </form> :
          <div>
            <div className={'okLogo signUpOkLogo'}>✓</div>
            <div className="contactFormSentMsg signUpSuccessText">
              {lang.SIGNUP_SUCCESS.replace('%1', this.state.email)}
            </div>
          </div>
        }
      </div>
    </div>
  )
}

export default SignUp