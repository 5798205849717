import React from "react"

import './PlayerReport.css'
import {ReactComponent as ReportIcon} from "../assets/comment-alert-outline_opt.svg"
import {toast} from "react-toastify"
import lang from "../lang/es"
import config from "../config"
import sendReport from "../actions/report"

class PlayerReport extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sending: false
    }
  }

  articleReported = (result) => {
    const msg = result ? lang.PLAYER_REPORT_BUTTON_DONE : lang.PLAYER_REPORT_BUTTON_ERROR

    toast.info(msg, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });

    this.setState({sending: false})
  }

  reportArticle = () => {
    if (this.state.sending) return
    this.props.setConfirm(
      lang.PLAYER_REPORT_CONFIRM,
      (res) => {if (res) {
        sendReport(
          JSON.stringify({
            subject: lang.PLAYER_REPORT_BUTTON_SUBJECT,
            from: config.CONTACT_EMAIL,
            message: {
              user: this.props.currentUser,
              discType: this.props.discType,
              discId: this.props.discId,
              track: this.props.track
            }
          }),
          this.articleReported
        )
        this.setState({sending: true})
      }}
    )
  }

  render = () => (
    <div className={`lawPlayerReportContainer ${this.props.className ? this.props.className : ''}`} onClick={this.reportArticle}>
      <ReportIcon className={'lawPlayerReportIcon'} />
      <div className={'lawPlayerReportText'}>{ this.state.sending ? lang.PLAYER_REPORT_BUTTON_SENDING : lang.PLAYER_REPORT_BUTTON_TEXT }</div>
    </div>
  )
}

export default PlayerReport