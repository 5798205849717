import React, { Component } from 'react'

class PdfViewer extends Component {
  render = () => (
    <iframe
      title="PDFjsViewer"
      src={`/pdfjs/web/viewer.html?file=${this.props.objectUrl}`}
      width={this.props.show ? '100%' : '0'}
      height={this.props.show ? '100%' : '0'}
      aria-label='PDF file'
      onLoad={this.props.onLoad}
    />
  )
  /*render = () => (
    <object type="application/pdf"
      data={this.props.data}
      width={this.props.show ? '100%' : '0'}
      height={this.props.show ? '100%' : '0'}
      aria-label='PDF file'
      onLoad={this.props.onLoad}
    />
  )*/
}

export default PdfViewer

/*import React, { Component } from 'react'
import * as pdfjs from 'pdfjs-dist/build/pdf'
import "pdfjs-dist/build/pdf.worker.entry"

class PdfViewer extends Component {
  componentDidMount = () => {
    this.renderPDF()
  }

  renderPDF = async () => {
    const loadingTask = pdfjs.getDocument({data: this.props.data})
    const pdf = await loadingTask.promise
    const page = await pdf.getPage(1)
    const viewport = page.getViewport({ scale: 1 })

    const canvas = document.getElementById('pdfCanvas')
    const context = canvas.getContext('2d')
    canvas.height = viewport.height
    canvas.width = viewport.width

    const renderContext = {
      canvasContext: context,
      viewport: viewport,
    }
    page.render(renderContext)
  }

  render = () => (
      <div>
        <canvas id="pdfCanvas" />
      </div>
    )
}

export default PdfViewer*/