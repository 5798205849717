import React from "react"
import lang from '../lang/es.js'

import './PlayerText.css'

import './PlayerTextEurLexCSS.css'

class PlayerText extends React.Component {
  containerElement = React.createRef()

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.html !== this.props.html ||
      prevProps.title !== this.props.title
    )
      this.containerElement.current.scrollTo(0, 0)
  }

  render = () => (
    <div ref={ this.containerElement } id={'lawTextContainer'} className={this.props.className}>
      <h1 id={'lawTextName'}>
        { this.props.showPlaceholder ? this.props.title : lang.PLACEHOLDER_ARTICLE_TITLE}
      </h1>
      {
        this.props.subtitle ?
          <h2 id={'lawTextSubtitle'}>
            { this.props.subtitle }
          </h2>
          : ''
      }
      {
        this.props.showPlaceholder ?
          <div id={'lawTextContent'} dangerouslySetInnerHTML={{ __html: this.props.html }} />
          : ''
      }
    </div>
  )
}

export default PlayerText