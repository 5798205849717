import React from "react"
import Logo from './Logo'
import { requestPasswordRestoreEmail } from '../actions/password'

import lang from '../lang/es'
import './PasswordRestore.css'

let initial = 0
let error = 1
let success = 2

class PasswordRestore extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: this.props.currentUser,
      working: false,
      status: initial
    }
  }

  handleUserChange = (event) => this.setState({ user: event.target.value })

  componentDidUpdate = (prevProps) => {
    if (prevProps.show !== this.props.show && this.props.show)
      this.setState({ user: this.props.currentUser, working: false, status: initial })
  }

  passwordRestoreResponse = (result) => {
    // Show success or error message based on result
    this.setState({
      working: false,
      status: result ? success : error
    })
  }

  requestPasswordRestore = (e) => {
    e.preventDefault()
    if (this.state.user.length > 0) {
      this.setState({working: true})
      requestPasswordRestoreEmail(this.state.user, this.passwordRestoreResponse)
    }
  }

  render = () => (
    <form id={'passwordRestoreContainer'} className={ this.props.show ? 'showPasswordRestore fullScreenModal' : 'hidePasswordRestore fullScreenModal' }>
      <div className='centeredCard passwordRestoreCard'>
        <Logo />
        { this.state.status === success ?
          <div className='cardMessage passwordRestoreSuccess'>
            { lang.PASSWORD_RESTORE_SUCCESS }
          </div>
          :
          <div>
            <input className="cardInput" type="email" name="email" autoComplete="username" id="username" placeholder={ lang.HINT_EMAIL } required={true} value={ this.state.user }
              onChange={ this.handleUserChange }/>
            <div
              className={ this.state.status ? 'cardMessage passwordRestoreError passwordRestoreErrorShow' : 'cardMessage passwordRestoreError' }>
              { this.state.status === error ? lang.PASSWORD_RESTORE_ERROR : '' }
            </div>
            { this.state.working ?
              <div className="passwordRestoreLoading"> { lang.PASSWORD_RESTORE_LOADING } </div>
              :
              <input type={'submit'} className="rcl" onClick={ this.requestPasswordRestore } value={ lang.PASSWORD_RESTORE_BUTTON } />
            }
          </div>
        }
        <button type={'button'} className="rcl rclSecondary passwordRestoreBackButton" onClick={ this.props.togglePasswordRestore }>
          { lang.BACK_BUTTON }
        </button>
      </div>
    </form>
  )
}

export default PasswordRestore