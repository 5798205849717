import React from "react"
import iOsBackgroundAudioWorkaround from "../utils/iOsBackgroundAudioWorkaround"

class IOsBackgroundAudioComponent extends React.Component {
  componentDidMount = () => iOsBackgroundAudioWorkaround(this.props.enabled)
  componentDidUpdate = (prevProps) => {
    if (prevProps.enabled !== this.props.enabled)
      iOsBackgroundAudioWorkaround(this.props.enabled)
  }

  render = () => (
    <div id={"iOsBackgroundAudio"} />
  )
}

export default IOsBackgroundAudioComponent