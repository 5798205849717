import React from "react"

import lang from '../lang/es'

import './ModalFilePicker.css'

class ModalFilePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedFile: null,
      errorMessage: null
    }
  }

  fileError = (msg) =>
    this.setState({ selectedFile: null, errorMessage: msg })

  inputAcceptedFiles = () =>
    this.props.fileTypes.concat(this.props.extensions).join(',')

  handleChange = (e) => {
    const selectedFile = e.target.files[0]

    if (!this.props.fileTypes.includes(selectedFile.type)) {
      this.fileError(lang.FILE_PICKER_WRONG_FORMAT)
    } else if (selectedFile.size > this.props.maxSize) {
      this.fileError(lang.FILE_PICKER_TOO_BIG.replace('%1', this.props.maxSize / 1024 / 1024))
    } else {
      this.setState({ selectedFile, errorMessage: null })
    }
  }

  upload = () => {
    this.props.cb(this.state.selectedFile)
    this.props.close()
  }

  render = () => (
    <div
      className={`modalFilePickerContainer${this.props.show ? '' : ' modalFilePickerContainerHide'}`}
      onClick={this.props.close}
    >
      <div
        className={`modalFilePickerBox${this.props.show ? '' : ' modalFilePickerBoxHide'}`}
        onClick={(evt) => evt.stopPropagation()}
      >

        <div className={'modalFilePickerMessage'}>
          {this.props.message}
        </div>

        <input
          type="file"
          name="selectedFile"
          accept={this.inputAcceptedFiles()}
          onChange={this.handleChange}
        />

        {this.state.errorMessage ?
          <div>
            {this.state.errorMessage}
          </div>
          : ''
        }

        <div className={'modalFilePickerButtonBar'}>
          <button className={'rcl'} onClick={this.upload}>
            {lang.FILE_PICKER_UPLOAD}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalFilePicker