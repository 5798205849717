import React from "react"

import {
  deleteCustomContent,
  getCustomPlaylist,
  getCustomTrack,
  setCustomContentPurchase
} from "../actions/customContent"
import {ReactComponent as SadIcon} from "../assets/emoticon-sad-outline.svg"
import {ReactComponent as MenuIcon} from "../assets/dots-vertical.svg"
import {ReactComponent as TtsIcon} from "../assets/volume-medium.svg"
import {ReactComponent as TimerIcon} from "../assets/timer-sand.svg"
import {ReactComponent as TrashIcon} from "../assets/delete.svg"
import {ReactComponent as PencilIcon} from "../assets/pencil.svg"

import lang from '../lang/es'
import './PlayerPlaylistCustom.css'
import {toast} from "react-toastify"
import Spinner from "./Spinner"
import renewAuth from "../actions/auth"
//import config from "../config"
import TooltippedText from "./TooltippedText"

class PlayerPlaylistCustom extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      playlist: null, //[]
      track: 0,
    }
  }

  componentDidMount = () => this.getPlaylist()

  getPlaylist = () => getCustomPlaylist((rawPlaylist) => this.setPlaylist(this.sortPlaylistByStatusAndTitle(rawPlaylist)))

  sortPlaylistByStatusAndTitle = (rawPlaylist) => {
    const ready = {}
    const queued = {}
    const others = {}

    const baseIds = Object.keys(rawPlaylist)

    let titleToId = []
    for (const baseId of baseIds) {
      const current = rawPlaylist[baseId][Object.keys(rawPlaylist[baseId]).length-1]
      titleToId.push(current)
    }
    titleToId = titleToId.sort((a,b) => a.title > b.title ? 1 : -1)

    for (let tid of titleToId) {
      if (tid.status === 'ready') ready[tid.id] = tid
      else if (tid.status === 'queued') queued[tid.id] = tid
      else others[tid.id] = tid
    }

    return {...ready, ...queued, ...others}
  }

  setPlaylist = (playlist) => this.setState({ playlist })

  setTrack = (track, start) => {
    getCustomTrack(
      track,
      false,
      (playable) => this.playTrack(playable, start || 0)
    )
    this.setState({ track })
  }

  jump = (j) => {
    const nextTrack = this.state.track + j
    if (nextTrack > -1 && nextTrack < this.state.playlist.length)
      this.setTrack(nextTrack)
  }

  playTrack = (playable, start) => {
    this.props.playTrack(
      {
        discId: this.state.track,
        track: this.state.track,
        title: playable.text.title,
        html: playable.text.html,
        pdf: playable.text.pdf,
        audioUrl: playable.audioUrl,
        navUrl: this.genNavUrl(this.state.track),
        retry: (start) => this.setTrack(this.state.track, start),
        next: () => this.jump(1),
        prev: () => this.jump(-1)
      },
      start
    )

    if (this.state.playlist[this.state.track].status === 'saved') {
      toast.warn(lang.PLAYLIST_CUSTOM_CONTENT_NO_TTS_PURCHASED, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        onClick: () => this.confirmTts(this.state.track)
      })
    }
  }

  genTtsResponse = (result, insufficientCredits, price) => {
    if (result) {
      toast.info(lang.PLAYLIST_CUSTOM_CONTENT_TTS_PURCHASE_OK, {
        position: "top-center",
        autoClose: 10000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      })
      this.setState({playlist: null})
      this.getPlaylist()
      renewAuth((tokenCookie) => this.props.setAuthToken(tokenCookie))
    } else {
      if (insufficientCredits) {
        this.ttsErrorInsufficientCredits(price)
      } else {
        toast.error(lang.PLAYLIST_CUSTOM_CONTENT_TTS_PURCHASE_ERROR, {
          position: "top-center",
          autoClose: 10000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        })
      }
    }
  }

  ttsErrorInsufficientCredits = (price) => {
    this.props.setConfirm(
      <TooltippedText
        text={lang.PLAYLIST_CUSTOM_NOT_ENOUGH_CREDITS}
        keyword={lang.TOOLTIP_PAGE_KEYWORD}
        tooltipContent={lang.TOOLTIP_PAGE_EXPLANATION}
      />,
      (res) => {if (res) this.props.toggleSubscription()}
    )
  }

  genTts = (id) => {
    setCustomContentPurchase(
      id,
      (result, insufficientCredits) =>
        this.genTtsResponse(result, insufficientCredits, this.state.playlist[id].price)
    )
  }

  confirmTts = (id, evt) => {
    if (evt) {
      evt.stopPropagation()
      evt.preventDefault()
    }

    this.props.setConfirm(
      lang.PLAYLIST_CUSTOM_CONTENT_TTS_CONFIRM,
      (res) => {if (res) this.genTts(id)}
    )
  }

  genNavUrl = (track) => `#custom=${track}`

  ttsWorking = (evt) => {
    evt.stopPropagation()
    evt.preventDefault()
    toast.info(lang.PLAYLIST_CUSTOM_CONTENT_TTS_WORKING, {
      position: "top-center",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })
  }

  confirmDeletion = (id, evt) => {
    if (evt) {
      evt.stopPropagation()
      evt.preventDefault()
    }
    this.props.setConfirm(lang.PLAYLIST_CUSTOM_CONTENT_DELETE_CONFIRM, (res) => {if (res) this.deleteContent(id)})
  }
  deleteContent = (id) => {
    deleteCustomContent(id, this.contentDeleted)
  }
  contentDeleted = (result) => {
    const msg = result ? lang.PLAYLIST_CUSTOM_CONTENT_DELETE : lang.PLAYLIST_CUSTOM_CONTENT_DELETE_ERROR
    toast.info(msg, {
      position: "top-center",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    })

    this.setState({ playlist: null})
    this.getPlaylist()
  }

  showTrackMenu= (id, evt) => {
    evt.preventDefault()
    evt.stopPropagation()

    //Ugly iOS Safari workaround:
    if (evt.target.tagName.toLowerCase() === 'button') evt.target.focus()
    else evt.target.parentElement.focus()
  }

  editCustomContent = (id, evt) => {
    evt.preventDefault()
    evt.stopPropagation()

    this.toggleContentEditor(id)
  }

  trackMenu = (id) => {
    return(
      <button className={'customTrackMenuButton'} onClick={(evt) => this.showTrackMenu(id, evt)}>
        <MenuIcon className={'customTrackMenuIcon'}/>
        <div className={'customTrackMenu'}>
            <ul className={'customTrackMenuList'}>
              {this.state.playlist[id].status === 'saved' ?
                <div>
                  <li className={'customTrackMenuListEntry'} onClick={(evt) => this.confirmTts(id, evt)}>
                    <TtsIcon className={'customTrackMenuListEntryIcon'}/>
                    {lang.PLAYLIST_CUSTOM_MENU_GENERATE}
                  </li>
                </div>
                : ''
              }
              {this.state.playlist[id].status === 'queued' ?
                <div>
                  <li className={'customTrackMenuListEntry'} onClick={(evt) => this.ttsWorking(evt)}>
                    <TimerIcon className={'customTrackMenuListEntryIcon'}/>
                    {lang.PLAYLIST_CUSTOM_MENU_GENERATING}
                  </li>
                </div>
                :
                <div>
                  {
                  <li className={'customTrackMenuListEntry'} onClick={(evt) => this.editCustomContent(id, evt)}>
                    <PencilIcon className={'customTrackMenuListEntryIcon'}/>
                    {lang.PLAYLIST_CUSTOM_MENU_EDIT}
                  </li>
                  }
                  <li className={'customTrackMenuListEntry'} onClick={(evt) => this.confirmDeletion(id, evt)}>
                    <TrashIcon className={'customTrackMenuListEntryIcon'}/>
                    {lang.PLAYLIST_CUSTOM_MENU_DELETE}
                  </li>
                </div>
              }
            </ul>
        </div>
      </button>
    )
  }

  trackEntry = (id, filter) => {
    if (filter && this.state.playlist[id].status !== filter) return
    return(
      <li key={id}>
        <a
          href={this.genNavUrl(id)}
          className={`track${this.state.track === id ? ' currentTrack' : ''} customTrackItemLink`}
          onClick={() => this.setTrack(id)}
        >
          {
            this.trackMenu(id)
          }
          <div className={'customTrackItemLinkText'}>
            <div className={'customTrackTitle'}>{this.state.playlist[id].title}</div>
          </div>
        </a>
      </li>
    )
  }
  trackList = () => {
    const label = (text) => {
      return <li key={`label${text}`} className={'customTrackListSectionLabel'}>
        { text }
      </li>
    }

    const ready = Object.keys(this.state.playlist)
                    .map((id) => this.trackEntry(id, 'ready')).filter((e) => e)
    const queued = Object.keys(this.state.playlist)
                    .map((id) => this.trackEntry(id, 'queued')).filter((e) => e)
    const other = Object.keys(this.state.playlist)
                    .map((id) => this.trackEntry(id, 'saved')).filter((e) => e)

    let fullList = []
    if (ready.length > 0) {
      fullList.push(label(lang.PLAYLIST_CUSTOM_PLAYLIST_READY_LABEL), ...ready)
    }
    if (queued.length > 0) {
      fullList.push(label(lang.PLAYLIST_CUSTOM_PLAYLIST_READY_QUEUED), ...queued)
    }
    if (other.length > 0) {
      fullList.push(label(lang.PLAYLIST_CUSTOM_PLAYLIST_READY_OTHER), ...other)
    }


    return(
      <ul className='customPlaylistList'>
        {
          fullList
        }
      </ul>
    )
  }

  toggleContentEditor = (contentId) =>
    this.props.setEditCustomContent(contentId, this.getPlaylist)

  render = () => (
    <div id={'playerCustomPlaylist'}>
      { !this.state.playlist ?
        <div className={'playerPlaylistLoading'}>
          <Spinner className={'playerPlaylistLoadingSpinner'}/>
          { lang.LOADING }
        </div>
        :
        Object.keys(this.state.playlist).length > 0 ?
          <div className={'customPlaylistListContainer'}>
            {
              this.trackList()
            }
            {/* No more "pages" system, so this (ugly) label is no longer needed
            <div className={'playerPlaylistCustomFreeCredits'}>
              { this.props.freeCredits > 0 ? lang.PLAYLIST_CUSTOM_REMAINING.replace('%1', Math.ceil(this.props.freeCredits/config.CHARACTERS_PER_PAGE).toString()) : ''}
            </div>
            */}
            <button className={'rcl playerPlaylistCustomAddButton'} onClick={() => this.toggleContentEditor()}>
              {lang.PLAYLIST_CUSTOM_CONTENT_ADD_BUTTON}
            </button>
          </div>
          :
          <div className={'playlistCustomPlaceholder'}>
            <SadIcon className={'playlistCustomSadIcon'}/>
            <span className={'playlistCustomSadIconText'}>{lang.PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_ICON_TEXT}</span>
            <span>{lang.PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_MESSAGE}</span>
            <span>{lang.PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_CALL_TO_ACTION}</span>
            <button className={'rcl playlistCustomPlaceholderButton'} onClick={() => this.toggleContentEditor()}>
              {lang.PLAYLIST_CUSTOM_CONTENT_PLACEHOLDER_ADD_BUTTON}
            </button>
          </div>
      }
    </div>
  )
}

export default PlayerPlaylistCustom