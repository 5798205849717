import React from "react"

import './TooltippedText.css'

const createTooltip = (text, keyword, tooltipContent) => {
  const fragments = text.split(keyword)

  const result = []
  result.push(fragments.shift())

  fragments.forEach((fragment, index) => {
    result.push(
      <span key={index} className={'tooltippedTextContainer'} onClick={(e) => e.stopPropagation()}>
        { keyword }
        <span key={'ttt' + index} className={'tooltippedTextTooltip'}>{ tooltipContent }</span>
      </span>
    )

    result.push(fragment)
  })

  return <span>{result}</span>
}

class TooltippedText extends React.Component {

  render = () =>
    <div>
      { createTooltip(this.props.text, this.props.keyword, this.props.tooltipContent) }
    </div>
}

export default TooltippedText