import React from "react"

import './ModalOptionsBinary.css'

class ModalOptionsBinary extends React.Component {
  answer = (res) => {
    this.props.cb(res)
    this.props.close()
  }

  render = () => (
    <div className={ `modalOptionsContainer${this.props.message ? '' : ' modalOptionsContainerHide'}`}>
      <div className={ `modalOptionsBox${this.props.message ? '' : ' modalOptionsBoxHide'}`}>
        <div className={'modalOptionsMessage'}>
          { this.props.message }
        </div>
        <div className={'modalOptionsButtonBar'}>
          <button className={'rcl modalOptionsButton'} onClick={ () => this.answer(0) }>
            {this.props.options[0]}
          </button>
          <button className={'rcl modalOptionsButton'} onClick={ () => this.answer(1) }>
            {this.props.options[1]}
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModalOptionsBinary