import config from "../config"

const signUp = (email, name, surname, cb) => {
  const request = JSON.stringify({email, name, surname})
  fetch(
    `${config.HTTP_PROTOCOL}://${config.RECITALEYES_LOGIN_URL}/signUp`,
    { method: 'post', body: request }
  )
    .then(res => cb(res.ok))
    .catch(() => cb(false))
}

export default signUp